import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';

import LanguageTexts from '../../common/language';
import PoolTimePicker from './components/PoolTimePicker';

export type CreatePoolProps = {
  _id?: string;
  name: string;
  entry: number;
  maxPlayer: number;
  amount: number;
  coins: number;
  startTime: moment.Moment | undefined;
  hours: number;
};

type PoolProps = {
  onSubmit: (data: CreatePoolProps) => void;
  onDelete: (id: any) => void;
  loading: boolean;
  errors: string[];
  initialValues: CreatePoolProps;
};

const PoolForm: React.FC<PoolProps> = ({
  onSubmit,
  onDelete,
  loading,
  errors,
  initialValues,
}: PoolProps): JSX.Element => {
  const { pools: poolsTxt } = LanguageTexts;
  const { app: appTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  const validate = (values: any) => {
    const errorsIn: any = {};

    if (!values.name) {
      errorsIn.name = poolsTxt.nameErrorText;
    }

    if (!values.entry) {
      errorsIn.entry = poolsTxt.entryErrorText;
    }

    if (!values.amount) {
      errorsIn.amount = poolsTxt.amountErrorText;
    }

    if (!values.coins) {
      errorsIn.coins = poolsTxt.coinsErrorText;
    }

    if (!values.maxPlayer) {
      errorsIn.maxPlayer = poolsTxt.maxPlayerErrorText;
    }

    if (!values.hours) {
      errorsIn.hours = poolsTxt.hoursErrorText;
    }

    if (!values.startTime) {
      errorsIn.startTime = poolsTxt.startTimeErrorText;
    }

    return errorsIn;
  };

  return (
    <Formik
      initialValues={initVals}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };
        onSubmit(input);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values, setFieldValue, errors: errorsIn, touched }) => {
        return (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="name">
                    {poolsTxt['input.name']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="name" type="text" className="form-control" />
                  {errorsIn.name && touched.name && (
                    <div className="invalid-feedback-msg">{errorsIn.name}</div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="entry">
                    {poolsTxt['input.entry']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="entry" type="number" className="form-control" />
                  {errorsIn.entry && touched.entry && (
                    <div className="invalid-feedback-msg">{errorsIn.entry}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="amount">
                    {poolsTxt['input.amount']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="amount" type="number" className="form-control" />
                  {errorsIn.amount && touched.amount && (
                    <div className="invalid-feedback-msg">
                      {errorsIn.amount}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="coins">
                    {poolsTxt['input.coins']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="coins" type="number" className="form-control" />
                  {errorsIn.coins && touched.coins && (
                    <div className="invalid-feedback-msg">{errorsIn.coins}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="startTime">
                        {poolsTxt['input.startTime']}
                        <sup className="invalid-feedback-msg">
                          &nbsp;
                          <span className="fa fa-star-of-life" />
                        </sup>
                      </label>
                      <PoolTimePicker
                        value={values.startTime}
                        disabled={false}
                        onChange={(newVal) =>
                          setFieldValue('startTime', newVal)
                        }
                      />
                      {errorsIn.startTime && touched.startTime && (
                        <div className="invalid-feedback-msg">
                          {errorsIn.startTime}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="hours">
                        {poolsTxt['input.hours']}
                        <sup className="invalid-feedback-msg">
                          &nbsp;
                          <span className="fa fa-star-of-life" />
                        </sup>
                      </label>
                      <Field
                        name="hours"
                        type="number"
                        className="form-control"
                      />
                      {errorsIn.hours && touched.hours && (
                        <div className="invalid-feedback-msg">
                          {errorsIn.hours}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="maxPlayer">
                    {poolsTxt['input.maxPlayer']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="maxPlayer"
                    type="number"
                    className="form-control"
                  />
                  {errorsIn.maxPlayer && touched.maxPlayer && (
                    <div className="invalid-feedback-msg">
                      {errorsIn.maxPlayer}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {poolsTxt.btnSave}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PoolForm;
